<template>
  <!-- modal -->
  <b-modal
    :id="'modal-file-edit'"
    :ref="'modal-file-edit'"
    :title="`Modifica ${fileEditData.name}`"
    ok-title="Salva"
    cancel-title="Chiudi"
    cancel-variant="outline-secondary"
    size="xl"
    centered
    @show="resetSendData"
    @hidden="resetSendData"
    @ok="handleOk"
  >
    <b-overlay
      id="overlay-background"
      :show="show"
      variant="transparent"
      :opacity="0.85"
      blur="2px"
      rounded="sm"
    >
      <validation-observer ref="editFileForm">
        <form
          ref="form"
          class="repeater-form"
          @submit.stop.prevent="validationForm"
        >
          <b-row>
            <b-col
              lg="6"
              md="6"
              sm="12"
              cols="12"
            >
              <b-row style="height: 500px; overflow-y: auto">
                <b-col
                  lg="12"
                  md="12"
                  sm="12"
                  cols="12"
                  class="text-center"
                >
                  <h3>Copertina</h3>
                  <b-img-lazy
                    class="m-auto"
                    width="200px"
                    :src="fileEditData.preview_link"
                  />
                </b-col>
                <b-col
                  lg="12"
                  md="12"
                  sm="12"
                  cols="12"
                  class="text-center mt-1"
                >
                  <h3 class="mb-5">
                    Contenuto
                  </h3>
                  <iframe
                    v-if="fileEditData.type === 'video'"
                    :src="`https://player.vimeo.com/video/${fileEditData.link}?h=fa76efa6df&amp;title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`"
                    width="100%"
                    height="100%"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    :title="fileEditData.name"
                  />

                  <b-carousel
                    v-else
                    id="carousel-caption"
                    :controls="fileEditData.link && fileEditData.link.length > 1"
                    :indicators="fileEditData.link && fileEditData.link.length > 1"
                    img-width="250"
                    img-height="250"
                    style="max-width: 500px"
                    class="m-auto"
                  >
                    <b-carousel-slide
                      v-for="url in handleLinks(fileEditData)"
                      :key="url"
                      class="card-img-overlay"
                      :img-src="url"
                    />
                  </b-carousel>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              lg="6"
              md="6"
              sm="12"
              cols="12"
            >
              <b-row>
                <b-col
                  lg="12"
                  md="12"
                  sm="12"
                  cols="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="name"
                    rules="required"
                  >
                    <b-form-group
                      label="Nome"
                      label-for="name"
                      invalid-feedback="Obbligatorio"
                    >
                      <b-form-input
                        id="name"
                        v-model="sendData.name"
                        :state="getValidationState(validationContext)"
                        required
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  lg="12"
                  md="12"
                  sm="12"
                  cols="12"
                >
                  <b-form-group
                    label="Tipologia"
                    label-for="type"
                    invalid-feedback="Obbligatorio"
                  >
                    <v-select
                      v-model="sendData.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="[{title: 'PDF (Verrà convertito in automatico in JPG)', value: 'file', icon: 'FileTextIcon'},{title: 'Video', value: 'video', icon: 'FilmIcon'},{title: 'Link esterno', value: 'link', icon: 'LinkIcon'}]"
                      label="title"
                      :reduce="option => option.value"
                    >
                      <template #option="{ title, icon }">
                        <feather-icon
                          :icon="icon"
                          size="16"
                          class="align-middle mr-50"
                        />
                        <span> {{ title }}</span>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="sendData.type === 'file'"
                  lg="12"
                  md="12"
                  sm="12"
                  cols="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="image"
                    rules="required"
                  >
                    <b-form-group
                      label="Documento"
                      label-for="image-input"
                    >
                      <b-form-file
                        v-model="sendData.file"
                        :state="getValidationState(validationContext)"
                        placeholder="Scegli documento"
                        accept=".pdf"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        Obbligatorio
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-else
                  lg="12"
                  md="12"
                  sm="12"
                  cols="12"
                >
                  <template v-if="sendData.type === 'video'">
                    <validation-provider
                      #default="validationContext"
                      name="link"
                      rules="integer"
                    >
                      <b-form-group
                        label="Codice Video"
                        label-for="image-input"
                      >
                        <b-form-input
                          id="link"
                          v-model="sendData.link"
                          :state="getValidationState(validationContext)"
                          required
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          Obbligatorio e numerico
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </template>
                  <template v-else>
                    <validation-provider
                      #default="validationContext"
                      name="link"
                      rules="url"
                    >
                      <b-form-group
                        label="Link"
                        label-for="link"
                      >
                        <b-form-input
                          id="link"
                          v-model="sendData.link"
                          :state="getValidationState(validationContext)"
                          required
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          deve essere in formato url (https://.....)
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </template>
                </b-col>
                <b-col
                  lg="12"
                  md="12"
                  sm="12"
                  cols="12"
                  class="text-center mt-2"
                >
                  <b-img-lazy
                    class="m-auto"
                    width="200px"
                    :src="makeUrlForPreview()"
                  />
                </b-col>
                <b-col
                  lg="12"
                  md="12"
                  sm="12"
                  cols="12"
                  class="mt-2"
                >
                  <validation-provider
                    #default="validationContext"
                    name="image"
                    rules=""
                  >
                    <b-form-group
                      label="Anteprima Immagine"
                      label-for="image-input"
                    >
                      <b-form-file
                        v-model="sendData.preview_image"
                        :state="getValidationState(validationContext)"
                        placeholder="Scegli anteprima"
                        accept=".png, .jpg"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        Obbligatorio
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

        </form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BRow,
  BCol,
  BOverlay,
  BFormFile,
  BFormInvalidFeedback,
  BImgLazy,
  BCarousel,
  BCarouselSlide,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required, alphaNum, integer, digits,
} from '@validations'
import { handleLinks } from '@core/utils/utils'
import vSelect from 'vue-select'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useFileList from '@/services/useFileList'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormGroup,
    BFormInput,
    BModal,
    BRow,
    BCol,
    BOverlay,
    BFormFile,
    BFormInvalidFeedback,
    BImgLazy,
    BCarousel,
    BCarouselSlide,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    fileEditData: {
      type: [Object, Array],
      required: true,
    },
    group: {
      type: [Object, Array],
      required: true,
    },
    argument: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      integer,
      digits,
    }
  },
  setup(props, {
    emit,
    refs,
  }) {
    // Use toast
    const toast = useToast()

    const show = ref(false)

    const blankSendData = ref({})

    const typeOptions = [{
      title: 'PDF (Verrà convertito in automatico in JPG)',
      value: 'file',
      icon: 'FileTextIcon',
    }, {
      title: 'Video',
      value: 'video',
      icon: 'FilmIcon',
    }, {
      title: 'Link esterno',
      value: 'link',
      icon: 'LinkIcon',
    }]

    const assingBlankSendData = () => {
      blankSendData.value = {
        id: props.fileEditData.id,
        name: props.fileEditData.name,
        group_file_id: props.fileEditData.group_file.id,
        file_type: props.fileEditData.file_type,
        type: props.fileEditData.type === 'image' ? 'file' : props.fileEditData.type,
        link: props.fileEditData.type === 'video' || props.fileEditData.type === 'link' ? props.fileEditData.link : null,
        file: null,
        to_jpg: 1,
        preview_image: null,
      }
    }
    assingBlankSendData()

    const sendData = ref(JSON.parse(JSON.stringify(blankSendData.value)))
    const resetSendData = () => {
      sendData.value = JSON.parse(JSON.stringify(blankSendData.value))
    }

    watch(() => props.fileEditData, () => {
      assingBlankSendData()
      resetSendData()
    })

    const onSubmit = () => {
      show.value = !show.value

      const formData = new FormData()
      formData.append('id', sendData.value.id)
      if (sendData.value.name) formData.append('name', sendData.value.name)
      if (sendData.value.group_file_id) formData.append('group_file_id', sendData.value.group_file_id)
      if (sendData.value.file_type) formData.append('file_type', sendData.value.file_type)
      if (sendData.value.file) formData.append('file', sendData.value.file)
      if (sendData.value.link) formData.append('link', sendData.value.link)
      formData.append('to_jpg', sendData.value.to_jpg)
      if (sendData.value.name) formData.append('type', sendData.value.type)
      if (sendData.value.preview_image) formData.append('preview_image', sendData.value.preview_image)

      store.dispatch('files/updateFile', formData)
        .then(() => {
          emit('refetch-data')
          refs['modal-file-edit'].toggle('#toggle-btn')
          toast({
            component: ToastificationContent,
            props: {
              title: 'Elemento aggiornato',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          console.log(error.response.statusText)
          if (Array.isArray(error.response.data)) {
            Object.values(error.response.data)
              .forEach(message => {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: message[0],
                    icon: 'AlertTriangleIcon',
                    variant: 'warning',
                  },
                })
              })
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: error.response.statusText || 'Errore Caricamento',
                icon: 'AlertTriangleIcon',
                variant: 'warning',
              },
            })
          }
        })
        .finally(() => {
          show.value = false
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetSendData)

    const {
      fetchFile,
      makeUrlForPreview,
      resolveDataForVSelect,
    } = useFileList()

    const handleSubmit = () => {
      onSubmit()
      // Hide the modal manually
      // this.$nextTick(() => {
      //   this.$refs['modal-create-quiz'].toggle('#toggle-btn')
      // })
    }

    const validationForm = () => {
      refs.editFileForm.validate()
        .then(success => {
          if (success) {
            handleSubmit()
          }
        })
    }

    const handleOk = bvModalEvt => {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      validationForm()
    }

    return {
      typeOptions,

      handleLinks,

      makeUrlForPreview,
      resolveDataForVSelect,

      fetchFile,
      show,

      sendData,
      onSubmit,

      validationForm,
      handleOk,

      refFormObserver,
      getValidationState,
      resetForm,
      resetSendData,
    }
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
