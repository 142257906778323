import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

export default function useFileList() {
  // Use toast
  const toast = useToast()

  const argumentFileType = ref(null)
  const refFileListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'preview_image',
      label: 'Anteprima',
      sortable: true,
    },
    {
      key: 'group_file',
      label: 'Gruppo',
      sortable: true,
    },
    {
      key: 'name',
      label: 'nome',
      sortable: true,
    },
    {
      key: 'type',
      label: 'tipo',
      sortable: true,
    },
    {
      key: 'link',
      label: 'link',
      sortable: false,
    },
    {
      key: 'created_at',
      label: 'creato il',
      sortable: true,
    },
    {
      key: 'updated_at',
      label: 'aggiornato il',
      sortable: true,
    },
    { key: 'azioni' },
  ]

  // Table Handlers
  const perPage = ref(25)
  const totalElements = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const getGroup = ref(true)
  const argumentId = ref(null)

  const dataMeta = computed(() => {
    const localDataCount = refFileListTable.value ? refFileListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localDataCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localDataCount,
      of: totalElements.value,
    }
  })

  const capitalizeFirstCharacter = string => string.charAt(0).toUpperCase() + string.slice(1)

  const fetchFile = (ctx, callback) => store
    .dispatch('files/fetchFiles', {
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      getGroup: getGroup.value,
      fileId: argumentId.value,
      file_type: argumentFileType.value,
    })
    .then(response => {
      const { data, meta } = response.data
      if (callback) {
        callback(data[argumentFileType.value])
      }
      totalElements.value = meta?.total
    })
    .catch(error => {
      console.log(error.response)
      toast({
        component: ToastificationContent,
        props: {
          title: `Problemi nel caricamento ${argumentFileType.value}`,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const fetchGroupFile = () => {
    store
      .dispatch('files/fetchGroupFiles', {
        getGroup: true,
        file_type: argumentFileType.value,
      })
      .then(() => {})
      .catch(error => {
        console.log(error.response)
        toast({
          component: ToastificationContent,
          props: {
            title: `Problemi nel caricamento ${argumentFileType.value}`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const refetchData = () => {
    if (refFileListTable.value) refFileListTable.value.refresh()
    fetchFile()
    if (getGroup.value != null && argumentFileType.value != null) {
      fetchGroupFile()
    }
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const refetchFile = () => {
    store.dispatch('files/fetchFiles', { id: router.currentRoute.params.id })
      .then(() => {
      })
      .catch(error => {
        console.log(error.response)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Problemi nel caricamento utenti',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const getListFile = () => store.getters['files/getListFile']
  const getListGroupFile = () => store.getters['files/getListGroupFile']

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const typeResolver = currentType => {
    if (currentType === 'file' || currentType === 'File') return 'success'
    if (currentType === 'video' || currentType === 'Video') return 'info'
    return 'secondary' // Se archived
  }

  const statusNameResolver = currentStatusName => {
    if (currentStatusName === 'Pending') return 'In Sospeso'
    if (currentStatusName === 'To Pay') return 'Da Pagare'
    if (currentStatusName === 'Payed') return 'Pagato'
    if (currentStatusName === 'Sended') return 'Spedito'
    return 'secondary' // Se archived
  }

  const makeUrlForPreview = image => {
    if (image) {
      return URL.createObjectURL(image)
    }
    // eslint-disable-next-line global-require
    return require('@/assets/images/placeholder/placeholder-image.jpeg')
  }

  const resolveDataForVSelect = group => group.map(data => ({ title: `${data.name}`, label: `${data.name}`, value: data.id }))

  return {
    argumentFileType,
    tableColumns,
    // Options

    //
    capitalizeFirstCharacter,
    fetchFile,
    fetchGroupFile,
    refetchFile,
    getListFile,
    getListGroupFile,
    perPage,
    currentPage,
    totalElements,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    getGroup,
    refFileListTable,
    refetchData,

    // Extra Filters
    //
    typeResolver,
    statusNameResolver,
    makeUrlForPreview,
    resolveDataForVSelect,
  }
}
