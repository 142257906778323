<template>
  <!-- modal -->
  <b-modal
    :id="`modal-file-create`"
    :ref="`modal-file-create`"
    :title="`Crea un nuovo ${argument}`"
    ok-title="Salva"
    cancel-title="Chiudi"
    cancel-variant="outline-secondary"
    size="md"
    centered
    @show="resetSendData"
    @hidden="resetSendData"
    @ok="handleOk"
  >
    <b-overlay
      id="overlay-background"
      :show="show"
      variant="transparent"
      :opacity="0.85"
      blur="2px"
      rounded="sm"
    >
      <validation-observer ref="createFileForm">
        <form
          ref="form"
          class="repeater-form"
          @submit.stop.prevent="validationForm"
        >

          <b-row>
            <b-col
              lg="12"
              md="12"
              sm="12"
              cols="12"
            >
              <b-form-group
                label="Gruppo"
                label-for="group"
                invalid-feedback="Obbligatorio"
              >
                <v-select
                  v-model="sendData.group_file_id"
                  :reduce="val => val.value"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="resolveDataForVSelect()"
                  :clearable="false"
                  :required="true"
                />
              </b-form-group>
            </b-col>
            <b-col
              lg="12"
              md="12"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="name"
                rules="required"
              >
                <b-form-group
                  label="Nome"
                  label-for="name"
                  invalid-feedback="Obbligatorio"
                >
                  <b-form-input
                    id="name"
                    v-model="sendData.name"
                    :state="getValidationState(validationContext)"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              lg="12"
              md="12"
              sm="12"
              cols="12"
            >
              <b-form-group
                label="Tipologia"
                label-for="type"
                invalid-feedback="Obbligatorio"
              >
                <v-select
                  v-model="sendData.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="[{title: 'PDF (Verrà convertito in automatico in JPG)', value: 'file', icon: 'FileTextIcon'},{title: 'Video', value: 'video', icon: 'FilmIcon'},{title: 'Link esterno', value: 'link', icon: 'LinkIcon'}]"
                  label="title"
                  :clearable="false"
                  :reduce="option => option.value"
                >
                  <template #option="{ title, icon }">
                    <feather-icon
                      :icon="icon"
                      size="16"
                      class="align-middle mr-50"
                    />
                    <span> {{ title }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              v-if="sendData.type === 'file'"
              lg="12"
              md="12"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="image"
                rules="required"
              >
                <b-form-group
                  label="Documento"
                  label-for="image"
                >
                  <b-form-file
                    id="image"
                    v-model="sendData.file"
                    :state="getValidationState(validationContext)"
                    placeholder="Scegli documento"
                    accept=".pdf"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    Obbligatorio
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-else
              lg="12"
              md="12"
              sm="12"
              cols="12"
            >
              <template v-if="sendData.type === 'video'">
                <validation-provider
                  #default="validationContext"
                  name="codice"
                  rules="required|integer"
                >
                  <b-form-group
                    label="Codice Video"
                    label-for="codice"
                  >
                    <b-form-input
                      id="codice"
                      v-model="sendData.link"
                      :state="getValidationState(validationContext)"
                      required
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      Obbligatorio e numerico
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </template>
              <template v-else>
                <validation-provider
                  #default="validationContext"
                  name="link"
                  rules="required|url"
                >
                  <b-form-group
                    label="Link"
                    label-for="link"
                  >
                    <b-form-input
                      id="link"
                      v-model="sendData.link"
                      :state="getValidationState(validationContext)"
                      required
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      Obbligatorio e in formato url (https://.....)
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </template>
            </b-col>
            <b-col
              lg="12"
              md="12"
              sm="12"
              cols="12"
              class="text-center mt-2"
            >
              <b-img-lazy
                class="m-auto"
                width="200px"
                :src="makeUrlForPreview()"
              />
            </b-col>
            <b-col
              lg="12"
              md="12"
              sm="12"
              cols="12"
              class="mt-2"
            >
              <validation-provider
                #default="validationContext"
                name="image"
                rules=""
              >
                <b-form-group
                  label="Anteprima Immagine"
                  label-for="image-input"
                >
                  <b-form-file
                    v-model="sendData.preview_image"
                    :state="getValidationState(validationContext)"
                    placeholder="Scegli anteprima"
                    accept=".png, .jpg"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    Obbligatorio
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BRow,
  BCol,
  BOverlay,
  BFormFile,
  BFormInvalidFeedback,
  BImgLazy,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required, alphaNum, integer, digits,
} from '@validations'
import vSelect from 'vue-select'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormGroup,
    BFormInput,
    BModal,
    BRow,
    BCol,
    BOverlay,
    BFormFile,
    BFormInvalidFeedback,
    BImgLazy,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    group: {
      type: [Object, Array],
      required: true,
    },
    argument: {
      required: true,
      type: String,
    },
  },
  setup(props, {
    emit,
    refs,
  }) {
    // Use toast
    const toast = useToast()

    const show = ref(false)

    const capitalizeFirstCharacter = string => string.charAt(0)
      .toUpperCase() + string.slice(1)

    const blankSendData = {
      name: null,
      group_file_id: null,
      file_type: props.argument,
      link: null,
      file: null,
      type: 'video',
      to_jpg: 1,
      preview_image: null,
    }

    const typeOptions = [{
      title: 'PDF (Verrà convertito in automatico in JPG)',
      value: 'file',
      icon: 'FileTextIcon',
    }, {
      title: 'Video',
      value: 'video',
      icon: 'FilmIcon',
    }, {
      title: 'Link esterno',
      value: 'link',
      icon: 'LinkIcon',
    }]

    const sendData = ref(JSON.parse(JSON.stringify(blankSendData)))
    const resetSendData = () => {
      sendData.value = JSON.parse(JSON.stringify(blankSendData))
    }

    const makeUrlForPreview = () => {
      if (sendData.value.preview_image) {
        return URL.createObjectURL(sendData.value.preview_image)
      }
      // eslint-disable-next-line global-require
      return require('@/assets/images/placeholder/placeholder-image.jpeg')
    }

    const onSubmit = () => {
      show.value = !show.value

      const formData = new FormData()

      formData.append('name', sendData.value.name)
      formData.append('group_file_id', sendData.value.group_file_id)
      formData.append('file_type', sendData.value.file_type)
      formData.append('file', sendData.value.file)
      formData.append('link', sendData.value.link)
      formData.append('to_jpg', sendData.value.to_jpg)
      formData.append('type', sendData.value.type)
      formData.append('preview_image', sendData.value.preview_image)

      store.dispatch('files/addFile', formData)
        .then(() => {
          emit('refetch-data')
          refs['modal-file-create'].toggle('#toggle-btn')
          resetSendData()
          show.value = false
        })
        .catch(error => {
          Object.values(error.response.data)
            .forEach(message => {
              toast({
                component: ToastificationContent,
                props: {
                  title: message[0],
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              })
            })
          show.value = false
        })
    }

    const resolveDataForVSelect = () => props.group.map(data => ({
      title: `${data.name}`,
      label: `${data.name}`,
      value: data.id,
    }))

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetSendData)

    const handleSubmit = () => {
      onSubmit()
      // Hide the modal manually
      // this.$nextTick(() => {
      //   this.$refs['modal-create-quiz'].toggle('#toggle-btn')
      // })
    }

    const validationForm = () => {
      refs.createFileForm.validate()
        .then(success => {
          if (success) {
            handleSubmit()
          }
        })
    }

    const handleOk = bvModalEvt => {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      validationForm()
    }

    return {
      show,

      sendData,
      typeOptions,

      makeUrlForPreview,
      onSubmit,
      resolveDataForVSelect,

      refFormObserver,
      getValidationState,
      resetForm,
      resetSendData,

      capitalizeFirstCharacter,
      validationForm,
      handleOk,

      required,
      alphaNum,
      integer,
      digits,
    }
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
