<template>
  <!-- modal -->
  <b-modal
    :id="`modal-group-file-create`"
    :ref="`modal-group-file-create`"
    :title="`Crea un nuovo gruppo ${argument}`"
    ok-title="Salva"
    cancel-title="Chiudi"
    cancel-variant="outline-secondary"
    size="md"
    centered
    @show="resetFormData"
    @hidden="resetFormData"
    @ok="handleOk"
  >
    <b-overlay
      id="overlay-background"
      :show="show"
      variant="transparent"
      :opacity="0.85"
      blur="2px"
      rounded="sm"
    >
      <validation-observer ref="createGroupFileForm">
        <form
          ref="form"
          class="repeater-form"
          @submit.stop.prevent="validationForm"
        >

          <b-row>
            <b-col
              lg="12"
              md="12"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="name"
                rules="required"
              >
                <b-form-group
                  label="Nome"
                  label-for="name"
                  invalid-feedback="Obbligatorio"
                >
                  <b-form-input
                    id="name"
                    v-model="sendData.group_file_name"
                    :state="getValidationState(validationContext)"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

        </form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BFormInput, BModal, VBModal, BRow, BCol, BOverlay,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required, alphaNum, integer, digits,
} from '@validations'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useFileList from '@/services/useFileList'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BModal,
    BRow,
    BCol,
    BOverlay,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    argument: {
      required: true,
      type: String,
    },
  },
  setup(props, { emit, refs }) {
    // Use toast
    const toast = useToast()

    const show = ref(false)
    const document = ref(false)

    const {
      refetchFile,
      capitalizeFirstCharacter,
    } = useFileList(props.argument)

    const blankSendData = {
      group_file_name: null,
      file_type: props.argument,
    }

    const sendData = ref(JSON.parse(JSON.stringify(blankSendData)))
    const resetFormData = () => {
      sendData.value = JSON.parse(JSON.stringify(blankSendData))
    }

    const onSubmit = () => {
      show.value = !show.value
      store.dispatch('files/addGroupFile', sendData.value)
        .then(() => {
          emit('refetch-data')
          refs['modal-group-file-create'].toggle('#toggle-btn')
          resetFormData()
          show.value = false
        })
        .catch(error => {
          Object.values(error.response.data)
            .forEach(message => {
              toast({
                component: ToastificationContent,
                props: {
                  title: message[0],
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              })
            })
          show.value = false
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetFormData)

    const handleSubmit = () => {
      onSubmit()
      // Hide the modal manually
      // this.$nextTick(() => {
      //   this.$refs['modal-create-quiz'].toggle('#toggle-btn')
      // })
    }

    const validationForm = () => {
      refs.createGroupFileForm.validate()
        .then(success => {
          if (success) {
            handleSubmit()
          }
        })
    }
    const handleOk = bvModalEvt => {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      validationForm()
    }

    return {
      document,
      show,

      refetchFile,
      capitalizeFirstCharacter,

      sendData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      resetFormData,

      handleSubmit,
      validationForm,
      handleOk,

      required,
      alphaNum,
      integer,
      digits,
    }
  },
}
</script>

<style lang="scss" scoped>
    .repeater-form {
        overflow: hidden;
        transition: .35s height;
    }
</style>
