<template>
  <b-card
    no-body
    class="mb-0 mt-1"
  >
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>
          <v-select
            v-model="currentGroup"
            :reduce="val => val.value"
            style="min-width: 300px"
            class="ml-3"
            label="title"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="resolveDataForVSelect()"
            :clearable="true"
            @input="updateCurrentGroup"
          />
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0)'"
            class="ml-1"
            variant="primary"
            @click="$bvModal.show(`modal-group-file-create`)"
          >
            Aggiungi gruppo
          </b-button>
          <group-file-modal
            :argument="argument"
            @refetch-data="fetchGroupFile()"
          />
        </b-col>

        <!-- Add Item -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0)'"
              variant="primary"
              @click="$bvModal.show('modal-file-create')"
            >
              Aggiungi
            </b-button>
            <file-modal
              :group="getListGroupFile()"
              :argument="argument"
              @refetch-data="updateTable"
            />
          </div>
        </b-col>
      </b-row>
    </div>
    <b-table
      v-if="Object.values(getListGroupFile()).length"
      ref="refFileListTable"
      class="position-relative mh-200"
      :items="resolveTable()"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="Nessun elemento trovato"
      :sort-desc.sync="isSortDirDesc"
    >

      <!-- Column: Preview Image -->
      <template #cell(preview_image)="data">
        <div class="text-nowrap">
          <b-img-lazy
            width="200px"
            :src="data.item.preview_link"
          />
        </div>
      </template>

      <!-- Column: Group File -->
      <template #cell(group_file)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-bold">{{ data.item.group_file.name }}</span>
        </div>
      </template>

      <template #cell(name)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-bold">{{ data.item.name }}</span>
          <file-edit-button
            :argument="argument"
            :file.sync="data.item"
            @refetch-data="updateTable"
          />
        </div>
      </template>

      <template #cell(type)="data">
        <div class="text-nowrap">
          <b-badge
            :variant="typeResolver(data.item.type)"
            class="badge"
          >
            {{ data.item.type }}
          </b-badge>
        </div>
      </template>

      <template #cell(link)="data">
        <div
          v-for="(link, index) in handleLinks(data.item).slice(0, 4)"
          :key="index"
          class="text-nowrap"
        >
          <template v-if="index === 3">
            totale: {{ handleLinks(data.item).length }}
          </template>
          <template v-else>
            {{ link }}
          </template>
        </div>
      </template>

      <template #cell(created_at)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-bold">{{ data.item.created_at }}</span>
        </div>
      </template>

      <template #cell(updated_at)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-bold">{{ data.item.updated_at }}</span>
        </div>
      </template>

      <!-- Column: Actions -->
      <template #cell(azioni)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item @click="openDocument(handleLinks(data.item)[0])">
            <feather-icon icon="NavigationIcon" />
            <span
              class="align-middle ml-50"
            >Vedi</span>
          </b-dropdown-item>

          <b-dropdown-item @click="openModalEditFile(data.item)">
            <feather-icon icon="EditIcon" />
            <span
              class="align-middle ml-50"
            >Modifica</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="data.item.deletable"
            @click="swalConfirmDeleteFile(data.item.id)"
          >
            <feather-icon icon="XIcon" />
            <span
              class="align-middle ml-50"
            >Elimina</span>
          </b-dropdown-item>

        </b-dropdown>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalElements"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <file-modal-edit
      v-if="fileToEdit"
      :file-edit-data.sync="fileToEdit"
      :group="getListGroupFile()"
      :argument="argument"
      @refetch-data="updateTable"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
  BFormInput,
  BBadge,
  BButton,
  BDropdown,
  BDropdownItem,
  BImgLazy,
  // BLink,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { ref } from '@vue/composition-api'
import { handleLinks, openDocument } from '@core/utils/utils'
import useFileList from '@/services/useFileList'
import FileEditButton from '@/views/file/file-edit/FileEditButton.vue'
import GroupFileModal from '@/views/file/file-create/GroupFileModal.vue'
import FileModal from '@/views/file/file-create/FileModal.vue'
import FileModalEdit from '@/views/file/file-create/FileModalEdit.vue'
// Notification

export default {
  name: 'FileTable',
  components: {
    FileModalEdit,
    FileEditButton,
    GroupFileModal,
    FileModal,
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BFormInput,
    BBadge,
    BButton,
    BDropdown,
    BDropdownItem,
    BImgLazy,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    argument: {
      required: true,
      type: String,
    },
  },
  setup(props, { root }) {
    const {
      tableColumns,
      argumentFileType,
      // Options

      //
      capitalizeFirstCharacter,
      fetchFile,
      fetchGroupFile,
      refetchFile,
      getListFile,
      getListGroupFile,
      perPage,
      currentPage,
      totalElements,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      getGroup,
      refFileListTable,
      refetchData,

      // Extra Filters
      //
      typeResolver,
      statusNameResolver,
    } = useFileList()
    argumentFileType.value = props.argument
    getGroup.value = null
    fetchGroupFile()
    fetchFile()

    const resolveDataForVSelect = () => getListGroupFile()
      .map(data => ({
        title: `${data.name}`,
        label: `${data.name}`,
        value: data.id,
      }))

    const currentGroup = ref(null)

    const resolveTable = () => {
      if (currentGroup.value) {
        return getListGroupFile()
          .find(group => group.id === currentGroup.value).file
      }
      return getListFile()
    }

    const updateCurrentGroup = () => {
      refFileListTable.value.refresh()
    }

    const updateTable = () => {
      fetchFile().finally(() => refFileListTable.value.refresh())
    }

    const fileToEdit = ref()
    const openModalEditFile = file => {
      // console.log(file)
      fileToEdit.value = file
      // Il timeout serve per dare il tempo all v-if di renderizzare la modal
      setTimeout(() => {
        root.$bvModal.show('modal-file-edit')
      }, 25)
    }

    const swalConfirmDeleteFile = id => {
      root.$swal({
        title: 'Sei sicura/o?',
        text: 'Non potrai annullare questa operazione',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si cancella!',
        cancelButtonText: 'Chiudi',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            store.dispatch('files/deleteFile', id)
              .then(() => {
                refetchData()
                root.$swal({
                  icon: 'success',
                  title: 'Cancellato!',
                  text: `${props.argument} cancellato`,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          } else if (result.dismiss === 'cancel') {
            root.$swal({
              title: 'Annullato',
              text: 'Cancellazione annullata',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          return result.value
        })
        .finally(() => updateTable())
    }

    return {
      currentGroup,
      argumentFileType,
      tableColumns,
      // Options

      //

      capitalizeFirstCharacter,
      fetchGroupFile,
      fetchFile,
      refetchFile,
      getListFile,
      getListGroupFile,
      perPage,
      currentPage,
      totalElements,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      getGroup,
      refFileListTable,
      refetchData,

      // Extra Filters
      //
      typeResolver,
      statusNameResolver,

      resolveDataForVSelect,
      resolveTable,
      updateCurrentGroup,
      updateTable,

      handleLinks,
      openDocument,
      fileToEdit,
      openModalEditFile,
      swalConfirmDeleteFile,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped/>
