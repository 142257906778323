<template>
  <div>
    <file-table
      argument="pressroom"
    />
  </div>
</template>

<script>

import {
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import FileTable from '@/views/file/file-list/FileTable.vue'

export default {
  name: 'FilePressroom',
  components: {
    FileTable,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
}
</script>

<style lang="scss">
    @import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
    .item-view-radio-group ::v-deep {
        .btn {
            display: flex;
            align-items: center;
        }
    }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
