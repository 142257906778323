<template>
  <!-- form -->
  <b-dropdown
    v-if="file && sendData"
    :id="`edit-file-${file.id}`"
    ref="fileEditButton"
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
    right
    size="sm"
    variant="flat-primary"
    menu-class="w-300"
    class="dropdown-icon-wrapper"
  >
    <template #button-content>
      <feather-icon icon="EditIcon" />
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-dropdown-form
        class="py-1"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- Name -->
        <validation-provider
          #default="validationContext"
          name="name"
          rules="required"
        >
          <b-form-group
            label="Nome"
            label-for="name"
          >
            <b-form-input
              :id="`name${file.id}`"
              v-model="sendData.name"
              :state="getValidationState(validationContext)"
              trim
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            size="sm"
            type="submit"
          >
            Modifica
          </b-button>
        </div>
      </b-dropdown-form>
    </validation-observer>

  </b-dropdown>
</template>

<script>
import {
  BButton,
  BDropdown,
  BDropdownForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useFileList from '@/services/useFileList'

export default {
  name: 'FileEditButton',
  components: {
    BButton,
    BDropdown,
    BDropdownForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    argument: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit, refs }) {
    // Use toast
    const toast = useToast()

    const {
      capitalizeFirstCharacter,
    } = useFileList(props.argument)

    const blankSendData = {
      id: props.file.id,
      name: props.file.name,
      file_name: props.file.file_name,
      type: props.file.type,
      link: props.file.type !== 'File' ? props.file.link : null,
    }

    const sendData = ref(JSON.parse(JSON.stringify(blankSendData)))
    const resetSendData = () => {
      sendData.value = JSON.parse(JSON.stringify(blankSendData))
    }

    const onSubmit = () => {
      const formData = new FormData()
      formData.append('id', sendData.value.id)
      if (sendData.value.name) formData.append('name', sendData.value.name)

      store.dispatch('files/updateFile', formData)
        .then(() => {
          emit('refetch-data')
          toast({
            component: ToastificationContent,
            props: {
              title: 'Elemento aggiornato',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          refs.fileEditButton.hide(true)
        })
        .catch(error => {
          console.log(error.response)
          Object.values(error.response.data)
            .forEach(message => {
              toast({
                component: ToastificationContent,
                props: {
                  title: message[0],
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              })
            })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetSendData)

    return {
      capitalizeFirstCharacter,
      sendData,
      resetSendData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style scoped>

</style>
